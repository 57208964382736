import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/css/index.css'
import './utils/rem'
import axios from 'axios';
import Ajax from 'axios';
import MetaInfo from 'vue-meta-info'

// import './utils/postmsgutil'

Vue.use(MetaInfo)

import {
    Lazyload
} from 'vant';

Vue.use(Lazyload);

Vue.prototype.$ajax = Ajax;
Vue.use(Lazyload, {
    lazyComponent: true,
});
Vue.use(Vant);

import VueTouch from 'vue-touch';

Vue.use(VueTouch, {
    name: 'v-touch'
});

Vue.component('ad-js', {
    render(createElement) {
        if (typeof HTMLIFrameElement != 'undefined') {
            return createElement('iframe', {
                attrs: {
                    src: '/ad.html?v=1.0#' + this.name,
                    class: 'iframe'
                }
            });
        } else {
            let config = {
                '320x100': {
                    id: 'ONEMOB_dovenovel_320x100_1',
                    path: 'dovenovel_320x100_1'
                },
                '300x250': {
                    id: 'ONEMOB_dovenovel_300x250_1',
                    path: 'dovenovel_300x250_2'
                },
                'video': {
                    id: 'ONEMOB_dovenovel_video_3',
                    path: 'dovenovel_video_3'
                },
            };
            return createElement('script', {
                attrs: {
                    id: config[this.name].id,
                    type: 'text/javascript',
                    src: config[this.name].src,
                    charset: 'UTF-8'
                }
            });
        }
    },
    props: {
        name: {
            type: String,
            required: true
        }
    }
});

if (location.host == 'wap.dovenovel.cn') { // 正式
    axios.defaults.baseURL = '//api.wykcshi.com/';
} else { // 本地
    axios.defaults.baseURL = '//gnapi.wykcshi.loc/';
}


Vue.prototype.$axios = axios



// http request 拦截器
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        const generalize = localStorage.getItem('generalize')
        if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = 'Bearer ' + token; //请求头加上token
        }
        if (generalize) {
            config.headers.Generalize = generalize;
        }
        return config
    },
    err => {
        return Promise.reject(err)
    })
// 时间过滤器
// 全局时间过滤器
Vue.filter("formatAdminTime", val => {
    // 在后台返回的时间基础上增加8小时
    if (val.toString().length === 10) val *= 1000;
    const date = new Date(val);
    // date.setHours(date.getHours() + 8);
    // 年月日
    const y = date.getFullYear();
    const m = (date.getMonth() + 1 + "").padStart(2, "0");
    const d = (date.getDate() + "").padStart(2, "0");
    // 时分秒
    const hh = (date.getHours() + "").padStart(2, "0");
    const mm = (date.getMinutes() + "").padStart(2, "0");
    const ss = (date.getSeconds() + "").padStart(2, "0");
    // 返回时间格式
    return `${y}-${m}-${d}`;
});
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')