<template>
    <div id="app">
        <!--        <keep-alive>-->
        <router-view />
        <!--        </keep-alive>-->
    </div>
</template>
<script>
    export default {
        name: "app",
        data() {
            return {};
        },
        metaInfo() {
            return {
                title: this.$store.state.Name,
            };
        },
        created() {
            this.official();
        },
        mounted() {

            // system.onmessage = function (data) {
            //   //当前平台不支持广告
            //   if(data==="0"){

            //   }
            // };

        },
        methods: {
            official() {
                this.$axios.post("/api/h5/init").then((res) => {
                    this.$store.commit("H5_name", res.data.data.name);
                });
            },
        },
    };
</script>
<style>
    .iframe {
        border: none;
        width: 100%;
    }
    div[id^=google_ads_iframe_] {
        display: block !important;
        margin: auto;
    }
</style>